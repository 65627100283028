import * as React from "react"
import { graphql, Link } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"
import ThemeContext from "../context/ThemeContext"



const CareersPage = data => {
  return (
    <ThemeContext.Consumer>
      {theme => (
        <Layout>
          <Seo title="" />

          <section
            id=""
            className={
              theme.dark ? "dark dark-5 p-b-0 p-t-0" : "light p-b-0 p-t-0"
            }
          >
            <div className="container-fluid">
              <div
                className="row animated fadeInUp visible"
                data-animate="fadeInUp"
              >
                <div className="col-lg-12 bg-danger p-100">
                  <h3 className="text-light">Careers</h3>

                  <div>
                    
                  </div>
                </div>
              </div>
            </div>
          </section>


        </Layout>
      )}
    </ThemeContext.Consumer>
  )
}
export default CareersPage
